<template>
  <div class="screen">
    <div class="screen__description">
      <div class="screen__header">
        <h2>Create different circles for<br/>your friends and family</h2>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import './styles.scss';
</style>